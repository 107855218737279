<template>
  <div class="mt-5">
    <v-card-title class="pa-4 mt-6">
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        dark
        class="px-3 border-radius-6 custom-data-table-action-btn"
        @click="openStatusOrderDialog()"
      >
        <v-icon class="mr-1"> mdi-plus </v-icon>
        Add
      </v-btn>
    </v-card-title>
    <div class="row">
      <div class="col-12 pa-8">
        <draggable
          v-model="listStatus"
          class="row kanban-stages-reorder"
          group="status"
          @end="onDragEnd"
        >
          <div
            v-for="(stage, index) in listStatus"
            :key="stage.id"
            class="col col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 pa-2"
            style="min-width: 300px"
          >
            <div class="d-flex w-100 cursor-pointer align-center">
              <v-icon class="mr-1"> mdi-drag </v-icon>
              <h2
                class="black--text white border-radius-10 status-header pa-3"
                :style="{
                  width: '100%',
                  border: `1px solid ${stage.color} !important`,
                  borderLeft: `4px solid ${stage.color} !important`,
                  borderRadius: '8px',
                }"
              >
                {{ stage.name }}
              </h2>
              <v-btn
                icon
                class="d-flex"
                @click="
                  statusOrderDialog = true;
                  isEdit = true;
                  newStatus = { ...stage };
                "
              >
                <PencilIcon :size="22"></PencilIcon>
              </v-btn>
              <v-btn
                icon
                class="d-flex"
                @click="deleteStasusDialog(index, stage.id)"
              >
                <TrashIcon :size="22"></TrashIcon>
              </v-btn>
            </div>
          </div>
        </draggable>
      </div>
      <div class="col pa-8">
        <v-btn
          class="border-radius-6 py-"
          depressed
          color="primary"
          :loading="loading"
          @click.prevent="saveStatusOrder"
        >
          Save
        </v-btn>
      </div>
    </div>

    <v-snackbar
      v-model="error"
      :timeout="3000"
      color="deep-orange accent-4"
      align-baseline="center"
    >
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="error = false"> Close </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="success"
      :timeout="3000"
      color="green accent-4"
      align-baseline="center"
    >
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="success = false"> Close </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="showDeleteTaskDialog" max-width="400px">
      <v-card>
        <v-card-title class="subtitle-1"
          >Are you sure you want to delete this Status?</v-card-title
        >
        <!-- <v-card-text class="body-2 text--grey darken-4">
          Any <b>Attachments</b> and <b>Subtasks</b> of this task will also be
          deleted
        </v-card-text> -->
        <v-card-actions class="mr-2">
          <v-spacer></v-spacer>
          <v-btn
            color="black"
            text
            class="capitalize"
            @click="closeDeleteStatusConfirmationDialog()"
            :disabled="isDeletingTask"
            >Cancel</v-btn
          >
          <v-btn
            color="red"
            text
            class="capitalize"
            @click="deleteStatus"
            :loading="isDeletingTask"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Dialog
      :isOpen="statusOrderDialog"
      :save="storeNewStatus"
      :title="isEdit ? 'Edit Status' : 'Add Status'"
      width="350px"
    >
      <v-row>
        <v-col cols="10">
          <v-text-field
            label="New Status"
            name="name"
            v-model="newStatus.name"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-icon
            class="color-icon"
            :style="{
              backgroundColor: newStatus.color,
              color: isDark(newStatus.color) ? 'white' : 'black',
              padding: `8px`,
              borderRadius: '20px',
            }"
            @click="colorPickerOpen = true"
          >
            mdi-palette
          </v-icon>
          <v-menu
            v-model="colorPickerOpen"
            max-width="300px"
            offset-y
            close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on"></span>
            </template>

            <v-color-picker v-model="newStatus.color"></v-color-picker>
          </v-menu>
        </v-col>
      </v-row>

      <template>
        <div style="display: flex; justify-content: center; gap: 20px">
          <v-btn
            color="secondary"
            width="100px"
            @click.prevent="
              statusOrderDialog = false;
              isEdit = false;
              newStatus = { name: null, color: '#006EFF' };
            "
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            @click.prevent="isEdit ? updateStatusName() : storeNewStatus()"
            width="100px"
          >
            Save....
          </v-btn>
        </div>
      </template>
    </Dialog>
    <v-snackbar
      v-model="alert.show"
      :timeout="3000"
      :color="alert.color"
      align-baseline="center"
    >
      {{ alert.message }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="alert.show = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import draggable from "vuedraggable";
import TrashIcon from "@/assets/icons/TrashIcon.vue";
import Dialog from "../../common/basic/Dialog.vue";
import PencilIcon from "../../../assets/icons/PencilIcon.vue";
import { isDark } from "../../../helper/uttils";

export default {
  props: {
    list_id: {
      type: [String, Number],
    },
    value: {
      type: Boolean,
    },
    isProcessing: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    draggable,
    TrashIcon,
    Dialog,
    PencilIcon,
  },

  computed: {
    ...mapState("projects", [
      "selectedListStatuses",
      "selectedListId",
      "cachesStatusesList",
    ]),
  },
  data() {
    return {
      statusOrderDialog: false,
      listStatus: [],
      loading: false,
      error: false,
      success: false,
      showDeleteTaskDialog: false,
      isDeletingTask: false,
      deleteStatusId: null,
      deleteIndex: null,
      colorPickerOpen: false,
      isEdit: false,
      message: "",
      newStatus: {
        name: null,
        color: "#006EFF",
        list_id: null,
      },
      alert: {
        show: false,
        message: "",
        color: "",
      },
      folderId: "",
      changeStatusId: null,
    };
  },
  watch: {
    selectedListStatuses: {
      handler(newVal) {
        this.listStatus = Object.assign([], newVal);
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations("projects", [
      "setSelectedListStatuses",
      "updateProjectCaches",
    ]),

    openStatusOrderDialog() {
      this.statusOrderDialog = true;
    },
    isDark(color) {
      return isDark(color);
    },
    showAlertMessage(isSucessMsg, message) {
      this.alert = {
        message: message || "",
        color: isSucessMsg ? "green accent-4" : "red accent-2",
        show: true,
      };
    },
    closeStatusOrderDialog() {
      this.listStatus = [];
      this.statusOrderDialog = false;
    },

    onDragEnd() {
      this.listStatus.forEach((stage, index) => {
        stage.order_index = index;
      });
    },
    saveStatusOrder() {
      let _self = this;
      _self.$axios
        .put("change_status_order/" + this.list_id, { data: this.listStatus })
        .then((response) => {
          let data = response.data;
          if (data.success) {
            this.$emit("refreshTasks");
            this.$emit("close");
            this.setSelectedListStatuses(this.listStatus);
            this.statusOrderDialog = false;
            this.showAlertMessage(true, data.message);
          } else {
            _self.error = true;
            _self.message = data.message;
            this.showAlertMessage(false, data.message);
          }
        });
    },

    storeNewStatus() {
      console.log("gereveb......");
      this.newStatus.list_id = this.selectedListId;
      this.$axios.post("status", this.newStatus).then((response) => {
        if (response.data.success) {
          this.statusOrderDialog = false;
          this.updateProjectCaches({
            key: "cachesStatusesList",
            value: false,
          });
          this.$emit("getListStatuses");
          this.newStatus = {
            name: null,
            color: "#006EFF",
          };
          this.showAlertMessage(true, response.data.message);
        } else {
          this.showAlertMessage(false, response.data.message);
        }
      });
    },

    updateStatusName() {
      let _self = this;
      _self.loading = true;

      _self.$axios
        .put("/update_status_name/" + this.newStatus.id, this.newStatus)
        .then((response) => {
          let data = response.data;
          _self.loading = false;

          if (data.success) {
            this.statusOrderDialog = false;
            this.updateProjectCaches({
              key: "cachesStatusesList",
              value: false,
            });
            this.$emit("getListStatuses");
            this.newStatus = {
              name: null,
              color: "#006EFF",
            };
            this.isEdit = false;
            this.showAlertMessage(true, data.message);
          } else {
            _self.error = true;
            _self.message = data.message;
            this.showAlertMessage(true, data.message);
          }
        });
    },

    deleteStasusDialog(index, stageId) {
      this.showDeleteTaskDialog = true;
      this.deleteStatusId = stageId;
      this.deleteIndex = index;
    },
    deleteStatus() {
      let _self = this;
      _self.$axios
        .delete("delete_status/" + this.deleteStatusId, {
          list_id: this.listStatus,
        })
        .then((response) => {
          let data = response.data;
          if (data.success) {
            this.listStatus.splice(this.deleteIndex, 1);
            this.listStatus.forEach((stage, index) => {
              stage.order_index = index;
              this.closeDeleteStatusConfirmationDialog();
            });
            this.updateProjectCaches({
              key: "cachesStatusesList",
              value: false,
            });
            this.$emit("getListStatuses");
          } else {
            this.closeDeleteStatusConfirmationDialog();
            _self.error = true;
            _self.message = data.message;
          }
        });
    },
    closeDeleteStatusConfirmationDialog() {
      this.showDeleteTaskDialog = false;
      this.deleteStatusId = null;
      this.deleteIndex = null;
    },
  },
  mounted() {
    this.$emit("getListStatuses");
    this.listStatus = Object.assign([], this.selectedListStatuses);
  },
};
</script>
<style>
.v-select.v-input--dense .v-chip {
  margin: 4px !important;
}
</style>
